/* eslint-disable react/react-in-jsx-scope */
import { lazy } from 'react';
import { RoutePath } from './app.constants';
import { ELSCommonUIConstants } from '../els.components';

export const AppRoutes = {
  getRoutes: () => [
    {
      path: RoutePath.LOGIN,
      component: lazy(() => import(/* webpackChunkName: "Login.page" */ '../../pages/login/Login.page')),
      isPrivate: false,
      exact: false,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.login,
          pageName: 'ama:login'
        }
      }
    },
    {
      path: RoutePath.CW_LOGOUT,
      component: lazy(() => import(/* webpackChunkName: "CoursewareLogout.page" */ '../../pages/logout/CoursewareLogout.page')),
      isPrivate: false,
      exact: false,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'ama:logout'
        }
      }
    },
    {
      path: RoutePath.HOME,
      component: lazy(() => import(/* webpackChunkName: "Home.page" */ '../../pages/home/Home.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.home,
          pageName: 'ama:home'
        }
      }
    },
    {
      path: RoutePath.EBOOK_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "EbookAssignmentEditor.page" */ '../../pages/ebook-assignment-editor/EbookAssignmentEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-editor:ebook'
        }
      }
    },
    {
      path: RoutePath.SKILL_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "SkillAssignmentEditor.page" */ '../../pages/skill-assignment-editor/SkillAssignmentEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-editor:skills'
        }
      }
    },
    {
      path: RoutePath.INTERACTIVE_REVIEW_ASSIGNMENT_EDITOR,
      component: lazy(() =>
        import(/* webpackChunkName: "InteractiveReviewAssignmentEditor.page" */ '../../pages/interactive-review-assignment-editor/InteractiveReviewAssignmentEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-editor:interactive'
        }
      }
    },
    {
      path: RoutePath.INTERACTIVE_REVIEW_ASSIGNMENT_PREVIEW,
      component: lazy(() =>
        import(/* webpackChunkName: "InteractiveReviewAssignmentPreview.page" */ '../../pages/interactive-review-assignment-preview/InteractiveReviewAssignmentPreview.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-preview:interactive'
        }
      }
    },
    {
      path: RoutePath.EAQ_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "EaqAssignmentEditor.page" */ '../../pages/eaq/assignment-editor/EaqAssignmentEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-editor:eaq'
        }
      }
    },
    {
      path: RoutePath.EAQ_MASTERY_RESET,
      component: lazy(() => import(/* webpackChunkName: "MasteryReset.page" */ '../../pages/eaq/mastery-reset/MasteryReset.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:mastery-reset'
        }
      }
    },
    {
      path: RoutePath.SIMULATION_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "SimulationEditor.page" */ '../../pages/simulation-editor/SimulationEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-editor:simulations'
        }
      }
    },
    {
      path: RoutePath.EVOLVE_RESOURCE_ASSIGNMENT_EDITOR,
      component: lazy(() => {
        return import(/* webpackChunkName: "EvolveResourceAssignmentEditor.page" */ '../../pages/evolve-resource-assignment-editor/EvolveResourceAssignmentEditor.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-editor:evolve-resource'
        }
      }
    },
    {
      path: RoutePath.EXTERNAL_LINK_ASSIGNMENT_EDITOR,
      component: lazy(() => {
        return import(/* webpackChunkName: "ExternalLinkAssignmentEditor.page" */ '../../pages/external-link-assignment-editor/ExternalLinkAssignmentEditor.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-editor:external-link'
        }
      }
    },
    {
      path: RoutePath.SHERPATH_LESSON_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "SherpathLessonEditor.page" */ '../../pages/sherpath-lesson-editor/SherpathLessonEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-editor:sherpath-lessons'
        }
      }
    },
    {
      path: RoutePath.SHERPATH_GROUP_ACTIVITY_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "SherpathGroupActivityEditor.page" */ '../../pages/sherpath-group-activity-editor/SherpathGroupActivityEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-editor:sherpath-group-activity'
        }
      }
    },
    {
      path: RoutePath.SHERPATH_POWERPOINT_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "SherpathPowerpointEditor.page" */ '../../pages/sherpath-powerpoint-editor/SherpathPowerpointEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-editor:sherpath-powerpoint'
        }
      }
    },
    // {
    //   path: RoutePath.SHERPATH_CASE_STUDY_ASSIGNMENT_EDITOR,
    //   component: lazy(() => import(/* webpackChunkName: "SherpathCaseStudyEditor.page" */ '../../pages/sherpath-case-study-editor/SherpathCaseStudyEditor.page')),
    //   isPrivate: true,
    //   exact: true,
    //   data: {
    //     analytics: {
    //       pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
    //       pageName: 'ama:assignment-editor:sherpath-case-study',
    //     }
    //   }
    // },
    {
      path: RoutePath.OSMOSIS_VIDEO_EDITOR,
      component: lazy(() => {
        return import(/* webpackChunkName: "OsmosisVideoEditor.page" */ '../../pages/osmosis-video-editor/OsmosisVideoEditor.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:osmosis-video-editor'
        }
      }
    },
    {
      path: RoutePath.APP_LINK_REDIRECT,
      component: lazy(() => import(/* webpackChunkName: "AppLinkRedirect.page" */ '../../pages/app-link-redirect/AppLinkRedirect.page')),
      isPrivate: false,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'ama:inter-app-redirect'
        }
      }
    }, {
      path: RoutePath.ADMIN,
      component: lazy(() => import(/* webpackChunkName: "Admin.page" */ '../../pages/admin/Admin.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:admin'
        }
      }
    }, {
      path: RoutePath.ASSIGNMENT_PERFORMANCE_REPORT,
      component: lazy(() => {
        return import(/* webpackChunkName: "AssignmentPerformance.page" */ '../../pages/assignment-performance/AssignmentPerformance.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'performance-report:assignment',
        }
      }
    }, {
      path: RoutePath.SHADOW_HEALTH_ASSIGNMENT_EDITOR,
      component: lazy(() => {
        return import(/* webpackChunkName: "ShadowHealthAssignmentEditor.page" */ '../../pages/shadow-health-assignment-editor/ShadowHealthAssignmentEditor.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:shadow-health-assignment-editor'
        }
      }
    },
    {
      path: RoutePath.SUPPORT,
      component: lazy(() => {
        return import(/* webpackChunkName: "Support.page" */ '../../pages/support/Support.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:support'
        }
      }
    },
    {
      path: RoutePath.ASSIGNMENT_DETAIL_REPORT,
      component: lazy(() => {
        return import(/* webpackChunkName: "AssignmentDetailReport.page" */ '../../pages/eaq/assignment-detail/AssignmentDetailReport.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-detail-report'
        }
      }
    },
    {
      path: RoutePath.ASSIGNMENT_PERFORMANCE_REPORT,
      component: lazy(() => {
        return import(/* webpackChunkName: "AssignmentPerformanceReport.page" */ '../../pages/assignment-performance/AssignmentPerformance.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ama:assignment-performance-report'
        }
      }
    },

  ]
};
